<template>
  <div :class="itemClass">
    <p><span>Рейтинг:</span></p>
    <star-rating
      :star-size="25"
      :active-color="activeColor"
      :inactive-color="inActiveColor"
      :border-color="borderColor"
      :border-width="3"
      :rounded-corners="true"
      :max-rating="10"
      :read-only="isRated"
      :rating="rating"
      :star-points="starPoints"
      @rating-selected="setRating"
    />
    <b v-if="isRated && isAuth">
      <br />
      <a class="rating-revote" href="#" @click.prevent="deleteRating">
        Переголосовать
      </a>
    </b>
  </div>
</template>

<script>
import { default as StarRating } from "vue-star-rating";
export default {
  name: "RatingItem",
  components: { StarRating },
  data() {
    return {
      isRated: true, //todo save to vuex
      starPoints: [
        23,
        2,
        14,
        17,
        0,
        19,
        10,
        34,
        7,
        50,
        23,
        43,
        38,
        50,
        36,
        34,
        46,
        19,
        31,
        17
      ]
    };
  },
  props: {
    itemClass: {
      type: [String, Array, Object],
      default: "modul-video-rating"
    },
    activeColor: {
      type: String,
      default: "#FBC80C"
    },
    inActiveColor: {
      type: String,
      default: "#202020"
    },
    borderColor: {
      type: String,
      default: "#202020"
    },
    itemId: {
      type: Number,
      default: 0
    },
    checkLink: String,
    deleteLink: String,
    setLink: String,
    paramName: String,
    rating: {
      type: Number,
      default: 0
    },
    updateItem: {
      type: Function
    }
  },
  computed: {
    isAuth() {
      return this.$auth.check();
    }
  },
  methods: {
    checkRated() {
      this.axios
        .get(this.checkLink, {
          params: {
            [this.paramName]: this.itemId
          }
        })
        .then(response => {
          this.isRated = response.data;
        });
    },
    setRating(rating) {
      this.axios
        .post(this.setLink, null, {
          params: {
            [this.paramName]: this.itemId,
            score: rating
          }
        })
        .then(response => {
          if (response.data) {
            this.isRated = true;
            this.updateItem(this.itemId);
          }
        });
    },
    deleteRating() {
      if (this.isRated) {
        this.axios
          .delete(this.deleteLink, {
            params: {
              [this.paramName]: this.itemId
            }
          })
          .then(response => {
            if (response.data) {
              this.isRated = false;
              this.updateItem(this.itemId);
            }
          });
      }
    }
  },
  created() {
    if (this.isAuth) {
      this.checkRated();
    }
  }
};
</script>

<style scoped></style>
